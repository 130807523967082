window.Blog = new (function () {

    const Swal = require('./plugins/sweetalert2');

    var that = this;


    this.init = function () {

        //change to visible or invisible banner
        $('.disable-blog').on('click', that.changeVisible);

        //Open categories
        $('.open-categories').on('click', that.openCategories);
        //select the product feature
        $(document).on('click', '.select-category', that.markSelection);
        $(document).on('click', '.saveSelectionCategories', that.saveMarkCategoriesSelection);

        //select the product ingredient
        $(document).on('click', '.select-blog-ingredient', that.markSelection);
        $(document).on('click', '.saveSelectionIngredient', that.saveMarkIngredientsSelection);

    };

    /**
     * change blog post visible / invisible
     */
    this.changeVisible = function () {

        //wait alert
        Swal.fire({
            title: 'Por favor, espere!',
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        });

        var blogID = $(this).data('blogid');
        var theButton = $(this);

        //ajax settings
        var settings = {
            url: changeBlogVisibleRoute,
            method: "POST",
            data: {
                '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                'blog_id': blogID,
            }
        }

        //ajax call
        $.ajax(settings).done(function (response) {
            var message = 'Entrada de blog cambió a visible en el sitio web.';
            $(theButton).html('Ocultar');
            $('.blog-' + blogID).removeClass('no-visible');
            $('.btnStd-' + blogID).removeClass('no-visible');

            try {
                $(theButton).tooltip('hide')
                    .attr('data-original-title', 'Clic para ocultar esta entrada')
                    .tooltip('fixTitle')
                    .tooltip('show');
            } catch (e) {

            }


            if (response == 'invisible') {
                message = 'Entrada de blog cambió a no visible en el sitio web.';
                $(theButton).html('Hacer visible');
                $('.blog-' + blogID).addClass('no-visible');
                $('.btnStd-' + blogID).addClass('no-visible');
                try {
                    $(theButton).tooltip('hide')
                        .attr('data-original-title', 'Clic para hacer visible esta entrada')
                        .tooltip('fixTitle')
                        .tooltip('show');
                } catch (e) {

                }
            }

            //ajax call fail
            Swal.fire(
                'Cambio exitoso',
                message,
                'success'
            );


        }).fail(function (xhr, status, error) {

            //ajax call fail
            Swal.fire(
                'Ocurrio un error',
                error,
                'error'
            );
        });

    };


    /**
     * Open categories
     */
    this.openCategories = function () {

        var blogID = $(this).data('id');
        var blogCategoriesHTML = that.composeCategoriesHTML(blogID);

        Swal.fire({
            title: 'Categorías',
            html: blogCategoriesHTML,
            showConfirmButton: false,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            customClass: 'categories-list'
        });

    };

    /**
     * Categories HTML
     * html
     * @param blogID
     */
    this.composeCategoriesHTML = function (blogID) {
        var html = "";
        html += '<div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">';

        if(blogCategories.length == 0) {

            html += '<label>';
            html += 'No hay categorías para mostrar'
            html += '</label>';
            html += '</div>';

        } else {

            $.each(blogCategories, function (index, category) {

                var autoSelected = '';

                $.each(category.blog_has_category, function (i, blogCategory) {
                    if (category.id == blogCategory.blog_category_id && blogID == blogCategory.blog_id) {
                        autoSelected = 'selected';
                    }
                });

                html += '<div class="wrap-feature select-category ' + autoSelected + '" data-type="category" data-blogid="' + blogID + '" data-id="' + category.id + '">';
                html += '<label>';
                html += category.name_es;
                html += '</label>';
                html += '<i class="icon fas fa-check"></i>';
                html += '</div>';

            });

            html += '<div class="wrap-footer">';
            html += '<button class="saveSelectionCategories btnStd primary w-50" data-blogid="'+ blogID +'"  data-form="select-save-category">Guardar selección</button>';
            html += '</div>';
            html += '</div>';

        }



        return html;
    };

    /**
     * mark the blog feature or ingredient
     */
    this.markSelection = function () {

        $(this).toggleClass('selected');

    };

    /**
     * Save Features
     */
    this.saveMarkCategoriesSelection = function () {
        var elements = $(document).find('.select-category.selected');
        var idsArray = [];
        var blogID = $(this).data('blogid');

        //show wait
        Swal.fire({
            title: 'Procesando',
            text: 'Por favor espera, guardando datos',
            showCloseButton: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        });

        $.each(elements, function (index, element) {

            var theID = $(element).data('id');
            blogID = $(element).data('blogid');

            idsArray.push(theID);

        });

        //send data
        $.ajax({
            type: 'POST',
            url: saveCategoriesSelectedRoute,
            data: {
                '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                'blog_id': blogID,
                'data': idsArray,
            },
            success: function (response) {

                blogCategories = $.parseJSON(response);

                Swal.fire(
                    'Ok!',
                    'Selección guardada',
                    'success'
                )
            }
        });

    };


    /**
     * blog ingredients
     */
    this.openRecipeIngredients = function () {

        var blogID = $(this).data('id');
        var blogIngredientsHTML = that.composeIngredientHTML(blogID);

        Swal.fire({
            title: 'Ingredientes',
            html: blogIngredientsHTML,
            showConfirmButton: false,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            customClass: 'blogIngredients-list'
        });

    };

    /**
     * blog Ingredient
     * html
     * @param blogID
     */
    this.composeIngredientHTML = function (blogID) {
        var html = "";
        html += '<div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">';

        if(blogIngredients.length == 0) {

            html += '<label>';
            html += 'No hay ingredientes para mostrar'
            html += '</label>';
            html += '</div>';

        } else {

            $.each(blogIngredients, function (index, ingredient) {

                var autoSelected = '';
                $.each(ingredient.blog_has_ingredients, function (i, blogIngredient) {
                    if (ingredient.id == blogIngredient.blog_ingredient_id && blogID == blogIngredient.blog_id) {
                        autoSelected = 'selected';
                    }
                });

                html += '<div class="wrap-feature select-blog-ingredient ' + autoSelected + '" data-type="ingredient" data-blogid="' + blogID + '" data-id="' + ingredient.id + '">';
                html += '<label>';
                html += ingredient.name;
                html += '</label>';
                html += '<i class="icon fas fa-check"></i>';
                html += '</div>';

            });

            html += '<div class="wrap-footer">';
            html += '<button class="saveSelectionIngredient btnStd btnStd-orange w-50" data-form="select-blog-ingredient">Guardar selección</button>';
            html += '</div>';
            html += '</div>';

        }



        return html;
    };

    /**
     * Save ingredients selected
     */
    this.saveMarkIngredientsSelection = function () {
        var elements = $(document).find('.select-blog-ingredient.selected');
        var idsArray = [];
        var blogID = 0;

        //show wait
        Swal.fire({
            title: 'Procesando',
            text: 'Por favor espera, guardando datos',
            showCloseButton: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        });

        $.each(elements, function (index, element) {

            var theID = $(element).data('id');
            blogID = $(element).data('blogid');

            idsArray.push(theID);

        });

        //send data
        $.ajax({
            type: 'POST',
            url: saveIngredientSelectedRoute,
            data: {
                '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                'blog_id': blogID,
                'data': idsArray,
            },
            success: function (response) {

                blogIngredients = $.parseJSON(response);

                Swal.fire(
                    'Ok!',
                    'Selección guardada',
                    'success'
                )
            }
        });

    };


})();
