window.Product = new (function () {

    const Swal = require('./plugins/sweetalert2');

    var that = this;
    var inputProductBannerMobile = $('#banner_mobile');
    var inputProductBannerDesk = $('#banner_desktop');
    var inputProductBannerMobileEs = $('#banner_mobile_es');
    var inputProductBannerDeskEs = $('#banner_desktop_es');
    var inputProductBannerMobileEn = $('#banner_mobile_en');
    var inputProductBannerDeskEn = $('#banner_desktop_en');
    var inputProductImgEs = $('#product_img_es');
    var inputProductImgEn = $('#product_img_en');
    var singleImg = $('#single_img');

    var onDragEnter = function (event) {
            event.preventDefault();
            $(this).addClass("dragover");
        },

        onDragOver = function (event) {
            event.preventDefault();
            if (!$(this).hasClass("dragover"))
                $(this).addClass("dragover");
        },

        onDragLeave = function (event) {
            event.preventDefault();
            $(this).removeClass("dragover");
        },

        onDrop = function (event) {
            event.preventDefault();
            $(this).removeClass("dragover");

            var device = $(this).data('device');
            var files = event.originalEvent.dataTransfer.files;
            that.bannerPreview(files, device);
        };

    this.init = function () {

        //active dropzone for banners
        $('.dropZone').on("dragenter", onDragEnter)
            .on("dragover", onDragOver)
            .on("dragleave", onDragLeave)
            .on("drop", onDrop);

        //$('.box').on('click', that.callInputFile);

        $(inputProductBannerMobile).on('change', function (event) {
            var device = $(this).data('device');
            var files = event.target.files;
            that.bannerPreview(files, device);
        });
        $(inputProductBannerDesk).on('change', function (event) {
            var device = $(this).data('device');
            var files = event.target.files;
            that.bannerPreview(files, device);
        });
        $(inputProductBannerMobileEs).on('change', function (event) {
            var device = $(this).data('device');
            var files = event.target.files;
            that.bannerPreview(files, device);
        });
        $(inputProductBannerDeskEs).on('change', function (event) {
            var device = $(this).data('device');
            var files = event.target.files;
            that.bannerPreview(files, device);
        });
        $(inputProductBannerMobileEn).on('change', function (event) {
            var device = $(this).data('device');
            var files = event.target.files;
            that.bannerPreview(files, device);
        });
        $(inputProductBannerDeskEn).on('change', function (event) {
            var device = $(this).data('device');
            var files = event.target.files;
            that.bannerPreview(files, device);
        });
        $(inputProductImgEs).on('change', function (event) {
            var device = $(this).data('device');
            var files = event.target.files;
            that.bannerPreview(files, device);
        });
        $(inputProductImgEn).on('change', function (event) {
            var device = $(this).data('device');
            var files = event.target.files;
            that.bannerPreview(files, device);
        });
        $(singleImg).on('change', function (event) {
            var device = $(this).data('device');
            var files = event.target.files;
            that.bannerPreview(files, device);
        });

        //change to visible or invisible banner
        $('.disable-product').on('click', that.changeVisible);

        try {
            $('#sortable').sortable();
            $('#sortable').disableSelection();
            $("#sortable").on("sortstop", that.updatePositions);
        } catch (e) {

        }

        //For radio image
        $('.label-radio-image').on('click', that.selectTheRadioButton);

        //Open certificates
        $('.open-certificates').on('click', that.openProductCertificates);
        //select the product feature
        $(document).on('click', '.select-product-feature', that.markSelection);
        $(document).on('click', '.saveSelectionFeatures', that.saveMarkSelection);

        //Open where to buy
        $('.open-where-to-buy').on('click', that.openProductWhereToBuy);
        //select the product store
        $(document).on('click', '.select-product-store', that.markSelection);
        $(document).on('click', '.saveSelectionWhereToBuy', that.saveMarkWhereToBuySelection);

        //Product presentation change
        $('#change-product').on('change', that.refreshProductPresentation);

        //create principal text for recipe ingredients
        $('.create-new-principal-title').on('click', that.openRecipePrincipalTitle)
        $(document).on('click', '.saveRecipePrincipalTitle', that.saveRecipePrincipalTitle);

        //create principal text for recipe preparations
        $('.create-new-principal-title-preparation').on('click', that.openRecipePreparationPrincipalTitle)
        $(document).on('click', '.saveRecipePreparationPrincipalTitle', that.saveRecipePreparationPrincipalTitle);

    };

    /**
     * Load image preview
     * @param files
     * @param device
     */
    this.bannerPreview = function (files, device) {

        //file exist
        if (files && files[0]) {
            var reader = new FileReader();

            //load src
            reader.onload = function (e) {
                $('#' + device).attr('src', e.target.result);
                $('#' + device).removeClass('d-none');

                $('.box.' + device).addClass('loaded');
            }

            reader.readAsDataURL(files[0]); // convert to base64 string

        }

    };

    /**
     * Trigger to input file
     */
    this.callInputFile = function () {

        var device = $(this).data('device');

        if (device == 'bannerMobile') {
            $(inputProductBannerMobile).trigger('click');
        } else if (device == 'bannerDesk') {
            $(inputProductBannerDesk).trigger('click');
        } else if (device == 'productImgEs') {
            $(inputProductImgEs).trigger('click');
        } else if (device == 'productImgEn') {
            $(inputProductImgEn).trigger('click');
        } else if (device == 'singleImg') {
            $(singleImg).trigger('click');
        }
    };

    /**
     * change product visible / invisible
     */
    this.changeVisible = function () {

        //wait alert
        Swal.fire({
            title: 'Por favor, espere!',
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        });

        var productID = $(this).data('productid');
        var theButton = $(this);

        //ajax settings
        var settings = {
            url: changeProductVisibleRoute,
            method: "POST",
            data: {
                '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                'product_id': productID,
            }
        }

        //ajax call
        $.ajax(settings).done(function (response) {
            var message = 'Producto cambió a visible en el sitio web.';
            $(theButton).html('Ocultar');
            $('.product-' + productID).removeClass('no-visible');
            $('.btnStd-' + productID).removeClass('no-visible');

            try {
                $(theButton).tooltip('hide')
                    .attr('data-original-title', 'Clic para ocultar este producto')
                    .tooltip('fixTitle')
                    .tooltip('show');
            } catch (e) {

            }


            if (response == 'invisible') {
                message = 'Producto cambió a no visible en el sitio web.';
                $(theButton).html('Hacer visible');
                $('.product-' + productID).addClass('no-visible');
                $('.btnStd-' + productID).addClass('no-visible');
                try {
                    $(theButton).tooltip('hide')
                        .attr('data-original-title', 'Clic para hacer visible este producto')
                        .tooltip('fixTitle')
                        .tooltip('show');
                } catch (e) {

                }
            }

            //ajax call fail
            Swal.fire(
                'Cambio exitoso',
                message,
                'success'
            );


        }).fail(function (xhr, status, error) {

            //ajax call fail
            Swal.fire(
                'Ocurrio un error',
                error,
                'error'
            );
        });

    };

    /**
     * Select the radio button
     */
    this.selectTheRadioButton = function () {

        $(this).find('input[type=radio]').prop("checked", true);

    };

    /**
     * product certificates
     */
    this.openProductCertificates = function () {

        var productID = $(this).data('id');
        var productCertificatesHTML = that.composeCertificateHTML(productID);

        Swal.fire({
            title: 'Certificados',
            html: productCertificatesHTML,
            showConfirmButton: false,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            customClass: 'productFeatures-list'
        });

    };

    /**
     * product certificate
     * html
     * @param productID
     */
    this.composeCertificateHTML = function (productID) {
        var html = "";
        html += '<div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">';


        $.each(productCertificates, function (index, certificate) {

            var autoSelected = '';

            $.each(certificate.product_has_certificate, function (i, prodCertificate) {
                console.log(certificate.id +' = ' + prodCertificate.product_certificate_id);
                console.log(productID +' = ' + prodCertificate.product_id);
                if (certificate.id == prodCertificate.product_certificate_id && productID == prodCertificate.product_id) {
                    autoSelected = 'selected';
                }
            });

            html += '<div class="wrap-feature certificate select-product-feature ' + autoSelected + '" data-type="feature" data-productid="' + productID + '" data-id="' + certificate.id + '">';
            html += '<img class="product-' + certificate.id + '"';
            html += '                 src="/uploads/products/certificates/' + that.toSlug(certificate.name) + '.webp"';
            html += '                 alt="' + certificate.name + '">';
            html += '<label>';
            html += certificate.name;
            html += '</label>';
            html += '<i class="icon fas fa-check"></i>';
            html += '</div>';

        });

        html += '<div class="wrap-footer">';
        html += '<button class="saveSelectionFeatures btnStd primary w-50" data-productid="'+ productID +'" data-form="select-product-feature">Guardar selección</button>';
        html += '</div>';
        html += '</div>';

        return html;
    };

    /**
     * mark the product feature or ingredient
     */
    this.markSelection = function () {

        $(this).toggleClass('selected');

    };

    /**
     * Save Mark selection
     */
    this.saveMarkSelection = function () {
        var elements = $(document).find('.select-product-feature.selected');
        var idsArray = [];
        var productID = $(this).data('productid');

        //show wait
        Swal.fire({
            title: 'Procesando',
            text: 'Por favor espera, guardando datos',
            showCloseButton: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        });

        $.each(elements, function (index, element) {

            var theID = $(element).data('id');
            productID = $(element).data('productid');

            idsArray.push(theID);

        });

        //send data
        $.ajax({
            type: 'POST',
            url: saveCertificatesSelectedRoute,
            data: {
                '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                'product_id': productID,
                'data': idsArray,
            },
            success: function (response) {

                productCertificates = $.parseJSON(response);

                Swal.fire(
                    'Ok!',
                    'Selección guardada',
                    'success'
                )
            }
        });

    };

    /**
     * Made slug
     * @param str
     */
    this.toSlug = function (str) {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();

        // remove accents, swap ñ for n, etc
        var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
        var to = "aaaaeeeeiiiioooouuuunc------";
        for (var i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '-') // collapse whitespace and replace by -
            .replace(/-+/g, '-'); // collapse dashes

        return str;
    };

    /**
     * product where to buy
     */
    this.openProductWhereToBuy = function () {

        var productID = $(this).data('id');
        var productWhereToBuyHTML = that.composeWhereToBuyHTML(productID);

        Swal.fire({
            title: 'Tiendas Principales',
            html: productWhereToBuyHTML,
            showConfirmButton: false,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            customClass: 'productWhereToBuy-list'
        });

    };

    /**
     * product Where to buy
     * html
     * @param productID
     */
    this.composeWhereToBuyHTML = function (productID) {
        var html = "";
        html += '<div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">';


        $.each(productWhereToBuy, function (index, store) {

            var autoSelected = '';

            $.each(store.product_has_principal_store, function (i, prodStore) {
                if( store.id == prodStore.principal_store_id && productID == prodStore.product_id) {
                    autoSelected = 'selected';
                }
            });

            html += '<div class="wrap-feature small select-product-store ' + autoSelected + '" data-type="store" data-productid="' + productID + '" data-id="' + store.id + '">';
            html += '<div class="img-head">';
            html += '<img class="product-' + store.id + ' img-fluid"';
            html += '                 src="/uploads/where-to-buy-principal-store/' + that.toSlug(store.name) + '.png"';
            html += '                 alt="' + store.name + '" style="min-height: auto; max-width: 75%;">';
            html += '</div>';
            html += '<label>';
            html += store.name;
            html += '</label>';
            html += '<i class="icon fas fa-check"></i>';
            html += '</div>';

        });

        var buttonDisabled = '';

        if( productWhereToBuy.length == 0 ) {
            html += '<label style="height: 80px">';
            html += 'No hay tiendas creadas'
            html += '</label>';

            buttonDisabled = 'disabled';
        }

        html += '<div class="wrap-footer">';
        html += '<button class="saveSelectionWhereToBuy btnStd primary w-50 '+buttonDisabled+'" '+buttonDisabled+' data-productid="' + productID + '" data-form="select-product-store">Guardar selección</button>';
        html += '</div>';
        html += '</div>';

        return html;
    };

    /**
     * Save product where buy selection
     */
    this.saveMarkWhereToBuySelection = function () {

        var elements = $(document).find('.select-product-store.selected');
        var idsArray = [];
        var productID = $(this).data('productid');

        //show wait
        Swal.fire({
            title: 'Procesando',
            text: 'Por favor espera, guardando datos',
            showCloseButton: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        });

        $.each(elements, function (index, element) {

            var theID = $(element).data('id');
            productID = $(element).data('productid');

            idsArray.push(theID);

        });

        //send data
        $.ajax({
            type: 'POST',
            url: saveWhereToBuySelectedRoute,
            data: {
                '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                'product_id': productID,
                'data': idsArray,
            },
            success: function (response) {

                productWhereToBuy = $.parseJSON(response);

                Swal.fire(
                    'Ok!',
                    'Selección guardada',
                    'success'
                )
            }
        });


    };

    /**
     * Open new recipe principal title
     */
    this.openRecipePrincipalTitle = function () {
        var recipeID = $(this).data('id');
        var html = '';

        html +'<div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">';
        html += '<div class="row" style="height: 120px;padding-top: 32px;">';
        html += '<div class="col-5 offset-1">';
        html += '<input type="text" id="principal_text_es" class="input" placeholder="Título (es)">';
        html += '</div>';
        html += '<div class="col-5">';
        html += '<input type="text" id="principal_text_en" class="input" placeholder="Título (en)">';
        html += '</div>';
        html += '<div class="wrap-footer">';
        html += '<button class="saveRecipePrincipalTitle btnStd primary w-50" data-recipeid="' + recipeID + '" data-form="select-product-store">Guardar</button>';
        html += '</div>';
        html += '</div>';

        Swal.fire({
            title: 'Crear Título',
            html: html,
            showConfirmButton: false,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            customClass: 'productFeatures-list'
        });
    };

    /**
     * Save recipe principal title
     */
    this.saveRecipePrincipalTitle = function () {

        var titleES = $(document).find('#principal_text_es').val();
        var titleEN = $(document).find('#principal_text_en').val();
        var recipeID = $(this).data('recipeid');

        //show wait
        Swal.fire({
            title: 'Procesando',
            text: 'Por favor espera, guardando datos',
            showCloseButton: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        });

        //send data
        $.ajax({
            type: 'POST',
            url: saveRecipePrincipalTitleRoute,
            data: {
                '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                'recipe_id': recipeID,
                'text_es': titleES,
                'text_en': titleEN,
            },
            success: function (response) {

                principalRecipeIngredientsTitles = $.parseJSON(response);

                $('select[name="ingredients_principal_title_id"] option').remove();


                $.each(principalRecipeIngredientsTitles, function (i, title) {

                    var optionSelected = (titleES == title) ? 'selected' : '';

                    $('select[name="ingredients_principal_title_id"]').append('<option '+ optionSelected +' value="' + i + '">' + title + '</option>');
                });

                Swal.fire(
                    'Ok!',
                    'Información guardada',
                    'success'
                )
            }
        });

    };

    /**
     * Open new recipe preparation principal title
     */
    this.openRecipePreparationPrincipalTitle = function () {
        var recipeID = $(this).data('id');
        var html = '';

        html +'<div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">';
        html += '<div class="row" style="height: 120px;padding-top: 32px;">';
        html += '<div class="col-5 offset-1">';
        html += '<input type="text" id="principal_text_es" class="input" placeholder="Título (es)">';
        html += '</div>';
        html += '<div class="col-5">';
        html += '<input type="text" id="principal_text_en" class="input" placeholder="Título (en)">';
        html += '</div>';
        html += '<div class="wrap-footer">';
        html += '<button class="saveRecipePreparationPrincipalTitle btnStd primary w-50" data-recipeid="' + recipeID + '" data-form="select-product-store">Guardar</button>';
        html += '</div>';
        html += '</div>';

        Swal.fire({
            title: 'Crear Título',
            html: html,
            showConfirmButton: false,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            customClass: 'productFeatures-list'
        });
    };

    /**
     * Save recipe principal title
     */
    this.saveRecipePreparationPrincipalTitle = function () {

        var titleES = $(document).find('#principal_text_es').val();
        var titleEN = $(document).find('#principal_text_en').val();
        var recipeID = $(this).data('recipeid');

        //show wait
        Swal.fire({
            title: 'Procesando',
            text: 'Por favor espera, guardando datos',
            showCloseButton: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        });

        //send data
        $.ajax({
            type: 'POST',
            url: saveRecipePreparationPrincipalTitleRoute,
            data: {
                '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                'recipe_id': recipeID,
                'text_es': titleES,
                'text_en': titleEN,
            },
            success: function (response) {

                principalRecipeIngredientsTitles = $.parseJSON(response);

                $('select[name="preparation_principal_title_id"] option').remove();


                $.each(principalRecipeIngredientsTitles, function (i, title) {

                    var optionSelected = (titleES == title) ? 'selected' : '';

                    $('select[name="preparation_principal_title_id"]').append('<option '+ optionSelected +' value="' + i + '">' + title + '</option>');
                });

                Swal.fire(
                    'Ok!',
                    'Información guardada',
                    'success'
                )
            }
        });

    };

    /**
     * Refresh page with product id
     */
    this.refreshProductPresentation = function () {

        var productID = $("#change-product option:selected").val();

        window.location.href = productPresentationRoute + '/' + productID;
    };

})();
