/**
 *
 * Dashboard class
 *
 */

window.Dashboard = new(function () {

    var that = this;

    /**
     * Init
     */
    this.init = function () {

        $( ".calendar-picker" ).datepicker({
            beforeShow: function(input, inst) {
                inst.dpDiv.css({
                    top: '216px',
                    marginLeft: input.offsetWidth + 'px'
                });
            }
        });

    }; //init



    this.slug = function(str) {
        var $slug = '';
        var trimmed = $.trim(str);
        $slug = trimmed.replace(/[^a-z0-9-]/gi, '-').
        replace(/-+/g, '-').
        replace(/^-|-$/g, '');
        return $slug.toLowerCase();
    }

})();
