window.Banner = new (function () {

    const Swal = require('./plugins/sweetalert2');

    var that = this;
    var inputBannerMobile = $('#banner_mobile');
    var inputBannerDesk = $('#banner_desktop');

    var onDragEnter = function(event) {
            event.preventDefault();
            $(this).addClass("dragover");
        },

        onDragOver = function(event) {
            event.preventDefault();
            if(!$(this).hasClass("dragover"))
                $(this).addClass("dragover");
        },

        onDragLeave = function(event) {
            event.preventDefault();
            $(this).removeClass("dragover");
        },

        onDrop = function(event) {
            event.preventDefault();
            $(this).removeClass("dragover");

            var device = $(this).data('device');
            var files = event.originalEvent.dataTransfer.files;
            that.bannerPreview(files, device);
        };

    this.init = function () {

        //active dropzone for banners
        $('.dropZone').on("dragenter", onDragEnter)
            .on("dragover", onDragOver)
            .on("dragleave", onDragLeave)
            .on("drop", onDrop);

        //$('.box').on('click', that.callInputFile);

        $(inputBannerMobile).on('change', function (event) {
            var device = $(this).data('device');
            var files = event.target.files;
            that.bannerPreview(files, device);
        });

        $(inputBannerDesk).on('change', function (event) {
            var device = $(this).data('device');
            var files = event.target.files;
            that.bannerPreview(files, device);
        });

        //change to visible or invisible banner
        $('.disable-banner').on('click', that.changeVisible);

        try {
            $('#sortable').sortable();
            $('#sortable').disableSelection();
            $( "#sortable" ).on( "sortstop", that.updatePositions );
        } catch (e) {

        }

        $('#sortable tr').mousedown(that.movingBanner);
        $('#sortable tr').mouseup(that.stopMovingBanner);
    };

    /**
     * Load image preview
     * @param files
     * @param device
     */
    this.bannerPreview = function (files, device) {
        var elementID = (device == 'mobile') ? 'bannerMobile' : 'bannerDesk';

        //file exist
        if (files && files[0]) {
            var reader = new FileReader();

            //load src
            reader.onload = function(e) {
                $('#' + elementID).attr('src', e.target.result);
                $('#' + elementID).removeClass('d-none');

                $('.box.' + device).addClass('loaded');
            }

            reader.readAsDataURL(files[0]); // convert to base64 string

            //pass to input file
            //(device == 'mobile') ? $(inputBannerMobile).val(files[0]) : $(inputBannerDesk).val(files[0]);
        }

    };

    /**
     * Trigger to input file
     */
    this.callInputFile = function () {

        var device = $(this).data('device');

        if(device == 'mobile') {
            $(inputBannerMobile).trigger('click');
        } else {
            $(inputBannerDesk).trigger('click');
        }
    };

    /**
     * change banner visible / invisible
     */
    this.changeVisible = function () {

        //wait alert
        Swal.fire({
            title: texts['please-wait'],
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        });

        var bannerID = $(this).data('bannerid');
        var theButton = $(this);

        //ajax settings
        var settings = {
            url: changeBannerVisibleRoute,
            method: "POST",
            data: {
                '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                'banner_id': bannerID,
            }
        }

        //ajax call
        $.ajax(settings).done(function (response) {
            var message = texts['changed-visible'];
            $(theButton).html(texts['hide']);
            $('.banner-'+bannerID).removeClass('banner-no-visible');

            try {
                $(theButton).tooltip('hide')
                    .attr('data-original-title', texts['click-hide'])
                    .tooltip('fixTitle')
                    .tooltip('show');
            } catch (e) {

            }


            if(response == 'invisible') {
                message = texts['change-not-visible'];
                $(theButton).html(texts['make-visible']);
                $('.banner-'+bannerID).addClass('banner-no-visible');
                try {
                    $(theButton).tooltip('hide')
                        .attr('data-original-title', texts['click-visible'])
                        .tooltip('fixTitle')
                        .tooltip('show');
                } catch (e) {

                }
            }

            //ajax call fail
            Swal.fire(
                texts['success-change'],
                 message,
                'success'
            );


        }).fail(function (xhr, status, error) {

            //ajax call fail
            Swal.fire(
                texts['error-occurred'],
                error,
                'error'
            );
        });

    };

    /**
     * Update banners positions
     */
    this.updatePositions = function (event, ui) {
        var data = $(this).sortable( "toArray" );

        //wait alert
        Swal.fire({
            title: texts['please-wait'],
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        });

        //ajax settings
        var settings = {
            url: updateBannerPositionRoute,
            method: "POST",
            data: {
                '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                'positions': data,
            }
        }

        //ajax call
        $.ajax(settings).done(function (response) {

            //ajax call fail
            Swal.fire(
                texts['success-change'],
                texts['new-position'],
                'success'
            );


        }).fail(function (xhr, status, error) {

            //ajax call fail
            Swal.fire(
                texts['error-occurred'],
                error,
                'error'
            );
        });

    };

    /**
     * Change cursor moving banner
     */
    this.movingBanner = function () {
        var id = $(this).attr('id');

        holdStarter = setTimeout(function() {
                // begin hold-only operation here, if desired
                $('#sortable #' + id).find('.item-grab').attr('style', 'cursor: grabbing;');
            }, 500);
    }
    this.stopMovingBanner = function () {
        var id = $(this).attr('id');

        // begin hold-only operation here, if desired
        $('#sortable #' + id).find('.item-grab').attr('style', 'cursor: grab;');
    }


})();


