import $ from 'jquery';
window.$ = window.jQuery = $;

require('../bootstrap');
require('./plugins/lity.min');
require('./plugins/sweetalert2');
require('./plugins/tiny');

require('./plugins/jquery-ui.min');
require('./Main.js');
require('./Dashboard');
require('./Product.js');
require('./Banner.js');
require('./Blog.js');