/**
 *
 * Main class
 *
 */

window.Main = new (function () {

    var that = this;

    const Swal = require('./plugins/sweetalert2');

    /**
     * Init
     */
    this.init = function () {

        //input file named
        $('input[type="file"]').change(that.fileInput);

        //delete element
        $('.delete-element').on('click', that.confirmDelete);

        //just numeric validation field
        $(document).on('keyup', '.justnumeric', that.justNumeric);

        //Tooltips
        $('.withTooltip').tooltip();

        //Report no dealer to admin
        $('.report-no-dealer').on('click', that.reportNoDealer);

        //Tooltips
        $('[data-toggle="tooltip"]').tooltip();

        //Show tooltip info
        $('.show-tooltip-info').on('click', that.showToolTilInfo);

        $('.normalizeText').on('keyup', that.normalizeText);

        $('#sortable tr').mousedown(that.movingBanner);
        $('#sortable tr').mouseup(that.stopMovingBanner);

        $('.copyNormalizeText').focusout(that.copyNormalizeText)

    }; //init


    /**
     * Custom file input
     */
    this.fileInput = function () {

        var theInput = $(this);
        if (theInput.is('.input-filepiker')) return;
        var theLabel = $(theInput).next();
        if (theInput[0].files[0]) {
            $(theLabel).text(theInput[0].files[0].name);
        }


    }; //init

       /**
     * Normalize text
     */
    this.normalizeText = function () {

        var theText = $(this).val();

        theText = that.toSlug(theText);

        $(this).val(theText);
    };
    
    /**
     * Made slug
     * @param str
     */

    this.toSlug = function (str) {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();

        // remove accents, swap ñ for n, etc
        var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
        var to = "aaaaeeeeiiiioooouuuunc------";
        for (var i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '-') // collapse whitespace and replace by -
            .replace(/-+/g, '-'); // collapse dashes

        return str;
    };
    
    this.copyNormalizeText = function () {

        var nameFiled = $(this).data('field');
        var theText = $(this).val();
        var copyToField = $('#'+nameFiled);

        $(copyToField).val( that.toSlug(theText));

    };
    /**
     * Show Confirm dialog
     */
    that.confirmDelete = function () {
        var elementID = $(this).data('id');
        var name = $(this).data('elementname');

        Swal.fire({
            title: '¿Desea eliminar ' +  name + '?',
            text: 'Una vez realizada la acción ya no se podrá recuperar el registro',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#ff8a00',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar'
        }).then((result) => {

            if (result.value) {
                $('#delete-form-' + elementID).submit();
            }
        })

    };

    /*
     * Show Alert
     * @param title
     * @param description
     * @param typeAlert
     */
    this.showAlert = function (title, description, typeAlert) {

        Swal.fire(title, description, typeAlert);

    }; //showErrorAlert

    /**
     * Validate field, just numeric
     */
    this.justNumeric = function (e) {
        $(this).val($(this).val().replace(/[^0-9\.]/g, '').replace(/[\.\^\'\!]/g, ''));
    }; //justNumeric

    /**
     * Wait alert
     */
    this.showWaitSwal = function () {
        Swal.fire({
            title: waitText,
            onBeforeOpen: () => {
                Swal.showLoading()
            }
        });
    };

    /**
     * Report no dealer section to admin
     */
    this.reportNoDealer = function () {
        that.showWaitSwal();

        var section = $(this).data('section');

        $.ajax({
            type: 'POST',
            url: reportNoDealerRoute,
            data: {
                '_token': $(document).find('meta[name="csrf-token"]').attr("content"),
                'section': section,
                'country': $(this).data('country'),
            },
            success: function (response) {
                that.showAlert(noDealerTitle, noDealerDescription, 'success');
            }
        });
    };

    /**
     * Show tooltip info
     * (text info)
     */
    this.showToolTilInfo = function () {
        var text = $(this).data('text');

        that.showAlert('', text, 'info');

    };

})();
